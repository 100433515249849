import LsdApiModel                                  from './global/LsdApiModel';
import { computed, toJS }                           from 'mobx';
import { getLabel as getExpenseStatusLabel }        from '../constants/ExpenseStatus';
import { getLabel as getExpensePaymentStatusLabel } from '../constants/ExpensePaymentStatus';
import { PaymentMethodsReferences }                 from '../constants/PaymentMethods';
import moment                                       from 'moment';

export default class ExpenseModel extends LsdApiModel {
	static get path(): string {
		return '/expenses/{?id}';
	}

	@computed
	public get accountingDocumentExpenses() {
		return toJS(this.get('accountingDocumentExpenses', []));
	}

	@computed
	public get amount() {
		return this.get('amount', 0);
	}

	@computed
	public get applicantIri() {
		return this.get('applicant.@id');
	}

	@computed
	public get amountInCompanyCurrency() {
		return this.get('amountInCompanyCurrency');
	}

	@computed
	public get currentResponsibles() {
		return this.get('currentResponsibles', []);
	}

	@computed
	public get currentResponsiblesIds() {
		return this.currentResponsibles.map(data => data.id);
	}

	@computed
	public get closed() {
		return this.get('closed', false);
	}

	@computed
	public get comment() {
		return this.get('comment');
	}

	@computed
	public get createdAt() {
		return this.get('createdAt');
	}

	@computed
	public get createdAtMoment() {
		return moment(this.createdAt);
	}

	@computed
	public get createdById() {
		return this.get('createdBy.id');
	}

	@computed
	public get createdByIri() {
		return this.get('createdBy.@id');
	}

	@computed
	public get currencyIri() {
		return this.get('currency.@id');
	}

	@computed
	public get description() {
		return this.get('description');
	}

	@computed
	public get reason() {
		return this.get('reason');
	}

	@computed
	public get expenseReportForIri() {
		return this.get('expenseReportFor.@id');
	}

	@computed
	get paymentMethod() {
		return this.get('paymentMethod');
	}

	@computed
	get paymentStatus() {
		return this.get('paymentStatus');
	}

	@computed
	get paymentStatusLabel() {
		return getExpensePaymentStatusLabel(this.paymentStatus);
	}

	@computed
	get expenseReportFor() {
		return this.get('expenseReportFor');
	}

	@computed
	get numberOfDeadlines() {
		return this.get('numberOfDeadlines', 1);
	}

	@computed
	get documents() {
		return this.get('documents', []);
	}

	@computed
	get paymentMethodLabel() {
		if (!this.paymentMethod) {
			return '';
		}

		return PaymentMethodsReferences[this.paymentMethod].label;
	}

	@computed
	get previousYearAmount() {
		return this.get('previousYearAmount', 0);
	}

	@computed
	get previousYearAmountInCompanyCurrency() {
		return this.get('previousYearAmountInCompanyCurrency', 0);
	}

	@computed
	get previousYearExpenseId() {
		return this.get('previousYearExpense.id');
	}

	@computed
	get previousYearExpenseIri() {
		return this.get('previousYearExpense.@id');
	}

	@computed
	get projectId() {
		return this.get('project.id');
	}

	@computed
	get projectIri() {
		return this.get('project.@id');
	}

	@computed
	get priority() {
		return this.get('priority', false);
	}

	@computed
	get recurrent() {
		return this.get('recurrent', false);
	}

	@computed
	get status() {
		return this.get('status');
	}

	@computed
	get withDeposit() {
		return this.get('withDeposit', false);
	}

	@computed
	get statusLabel() {
		return getExpenseStatusLabel(this.status);
	}

	@computed
	get title() {
		return this.get('title');
	}
}
