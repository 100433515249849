import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class ExpenseGroupModel extends LsdApiModel {
	static get path(): string {
		return '/expense_groups/{?id}';
	}

	@computed
	get companyId() {
		return this.get('company.id');
	}

	@computed
	get expenseGroupName() {
		return this.get('name');
	}
}
