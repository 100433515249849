import React           from 'react';
import {JtsScreenView} from 'react-jts-common';
import LoginForm       from '../forms/LoginForm/LoginForm';
import i18n            from 'components/i18n';
import {observer}      from 'mobx-react';

@observer
export default class LoginScreen extends React.Component {
	public render() {
		return <JtsScreenView title={i18n._('Connexion')}>
			<LoginForm {...this.props}/>
		</JtsScreenView>;
	}
}
