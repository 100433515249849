import AccountingDocumentModel from './AccountingDocumentModel';
import CurrencyModel           from 'models/CurrencyModel';
import { convertFromCurrencyToCurrency } from 'tools/currencyTools';

export default class AccountingDocumentBudgetModel extends AccountingDocumentModel {
	static get path(): string {
		return '/accounting_document_budgets/{?id}';
	}

	public getAmountLeft = (accountingDocumentCurrency?: CurrencyModel, companyCurrency?: CurrencyModel): {
		amountLeftInAccountingDocumentCurrency: number;
		amountLeftInCompanyCurrency: number;
	} => {
		const amountLeftInCompanyCurrency = this.get('amountInCompanyCurrency', 0) - this.get('accountingAmountLinked.amount', 0);

		const amountLeftInAccountingDocumentCurrency = convertFromCurrencyToCurrency(
			companyCurrency,
			accountingDocumentCurrency,
			undefined,
			amountLeftInCompanyCurrency,
		);

		return {
			amountLeftInAccountingDocumentCurrency,
			amountLeftInCompanyCurrency,
		};
	}

	public getAmountLinked = (accountingDocumentCurrency?: CurrencyModel, companyCurrency?: CurrencyModel): {
		amountLinkedInAccountingCurrency: number;
		amountLinkedInCompanyCurrency: number;
	} => {
		const amountLinkedInCompanyCurrency = this.get('accountingAmountLinked.amount', 0);

		const amountLinkedInAccountingCurrency = convertFromCurrencyToCurrency(
			companyCurrency,
			accountingDocumentCurrency,
			undefined,
			amountLinkedInCompanyCurrency,
		);

		return {
			amountLinkedInAccountingCurrency,
			amountLinkedInCompanyCurrency,
		};
	}

	public getAmountLinkedLeftToPay = (accountingDocumentCurrency?: CurrencyModel, companyCurrency?: CurrencyModel): {
		amountLinkedLeftToPayInAccountingCurrency: number;
		amountLinkedLeftToPayInCompanyCurrency: number;
	} => {
		const {
			amountLinkedInCompanyCurrency,
		} = this.getAmountLinked(accountingDocumentCurrency, companyCurrency);

		const {
			amountPaidInCompanyCurrency,
		} = this.getAmountPaid(accountingDocumentCurrency, companyCurrency);

		const amountLinkedLeftToPayInCompanyCurrency= amountLinkedInCompanyCurrency - amountPaidInCompanyCurrency;

		const amountLinkedLeftToPayInAccountingCurrency = convertFromCurrencyToCurrency(
			companyCurrency,
			accountingDocumentCurrency,
			undefined,
			amountLinkedLeftToPayInCompanyCurrency,
		);

		return {
			amountLinkedLeftToPayInAccountingCurrency,
			amountLinkedLeftToPayInCompanyCurrency,
		};
	}

	public getAmountPaid = (accountingDocumentCurrency?: CurrencyModel, companyCurrency?: CurrencyModel): {
		amountPaidInAccountingDocumentCurrency: number;
		amountPaidInCompanyCurrency: number;
	} => {
		const amountPaidInCompanyCurrency = this.get('accountingAmountPaid.amount', 0);

		const amountPaidInAccountingDocumentCurrency = convertFromCurrencyToCurrency(
			companyCurrency,
			accountingDocumentCurrency,
			undefined,
			amountPaidInCompanyCurrency,
		);

		return {
			amountPaidInAccountingDocumentCurrency,
			amountPaidInCompanyCurrency,
		};
	}

	public getAmounts = (accountingDocumentCurrency?: CurrencyModel, companyCurrency?: CurrencyModel): {
		amountInAccountingDocumentCurrency: number;
		amountInComapnyCurrency: number;
		amountLeftInAccountingDocumentCurrency: number;
		amountLeftInCompanyCurrency: number;
		amountLinkedInAccountingCurrency: number;
		amountLinkedInCompanyCurrency: number;
		amountLinkedLeftToPayInAccountingCurrency: number;
		amountLinkedLeftToPayInCompanyCurrency: number;
		amountPaidInAccountingDocumentCurrency: number;
		amountPaidInCompanyCurrency: number;
		amountToPayInAccountingDocumentCurrency: number;
		amountToPayInCompanyCurrency: number;
	} => {
		const amountInAccountingDocumentCurrency = this.amount || 0;

		const amountInComapnyCurrency = this.amountInCompanyCurrency || 0;

		const {
			amountLeftInAccountingDocumentCurrency,
			amountLeftInCompanyCurrency,
		} = this.getAmountLeft(accountingDocumentCurrency, companyCurrency);

		const {
			amountLinkedInAccountingCurrency,
			amountLinkedInCompanyCurrency,
		} = this.getAmountLinked(accountingDocumentCurrency, companyCurrency);

		const {
			amountLinkedLeftToPayInAccountingCurrency,
			amountLinkedLeftToPayInCompanyCurrency,
		} = this.getAmountLinkedLeftToPay(accountingDocumentCurrency, companyCurrency);

		const {
			amountPaidInAccountingDocumentCurrency,
			amountPaidInCompanyCurrency,
		} = this.getAmountPaid(accountingDocumentCurrency, companyCurrency);

		const amountToPayInAccountingDocumentCurrency = amountInAccountingDocumentCurrency - amountPaidInAccountingDocumentCurrency;

		const amountToPayInCompanyCurrency = amountInComapnyCurrency - amountPaidInCompanyCurrency;

		return {
			amountInAccountingDocumentCurrency,
			amountInComapnyCurrency,
			amountLeftInAccountingDocumentCurrency,
			amountLeftInCompanyCurrency,
			amountLinkedInAccountingCurrency,
			amountLinkedInCompanyCurrency,
			amountLinkedLeftToPayInAccountingCurrency,
			amountLinkedLeftToPayInCompanyCurrency,
			amountPaidInAccountingDocumentCurrency,
			amountPaidInCompanyCurrency,
			amountToPayInAccountingDocumentCurrency,
			amountToPayInCompanyCurrency,
		};
	}
}
