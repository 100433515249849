import React    from 'react';
import ReactDOM from 'react-dom';
import routes from './routes';
import App    from 'components/App/App';

import './documentAddEventListenerKeyPress';

const render = Component => {
	ReactDOM.hydrate(
		<Component
			routes={routes}
			history={history}
		/>,
		document.getElementById('main')
	);
};

render(App);

// if (module.hot) {
// 	module.hot.accept('./components/App/App', () => {
// 		const nextApp = require('./components/App/App').default;
// 		render(nextApp) ;
// 	});
// }
//
// if (!!ConfigProxy.get('SENTRY_DSN')) {
// 	Raven.config(ConfigProxy.get('SENTRY_DSN'), {
// 		release: version,
// 		environment: ConfigProxy.get('APP_ENV'),
// 	}).install();
// }
//
// Raven.setUserContext({});
