import './LayoutSider.scss';

import React                   from 'react';
import Icon                    from 'antd/lib/icon';
import Layout                  from 'antd/lib/layout';
import { inject, observer }    from 'mobx-react';
import { withRouter }          from 'react-router-dom';
import CompaniesNavigation     from './../Navigation/CompaniesNavigation';
import currentMenu             from '../../currentMenu';
import ExpenseGroupsNavigation from './../Navigation/ExpenseGroupsNavigation';
import session                 from '../../currentSession';

interface ILayoutSiderProps {
	appStore?: AppStore;
	history;
	location;
	organigramStore?: OrganigramStore;
}

@inject('appStore')
@inject('organigramStore')
@observer
class LayoutSider extends React.Component<ILayoutSiderProps> {
	public componentDidMount() {
		const {
			appStore,
			history,
			location,
		} = this.props;

		history.listen((newLocation) => this._historyListen(newLocation));

		this._historyListen(location);

		appStore.expenseToProcessStats
			.setMe(session.accountModel.id)
			.listenForFetch()
			.fetch();

		const {
			pathname,
		} = location;

		const expenseGroupRegex = /\/expense_groups\/(\d+)/;
		const matchExpenseGroup = pathname.match(expenseGroupRegex);

		let expenseGroupId = null;

		if (!!matchExpenseGroup) {
			expenseGroupId = matchExpenseGroup[1];

			if (!!matchExpenseGroup
				&& !!expenseGroupId
			) {
				this.props.appStore.setCurrentExpenseGroupId(expenseGroupId);
				this.props.organigramStore.setExpenseGroupId(expenseGroupId);
			}
		}
	}

	public render() {
		const {
			location,
		} = this.props;

		const {
			currentCompanyId,
			currentUserCompanyCollection,
		} = this.props.appStore;

		const {
			pathname,
		} = location;

		const currentCompany = currentUserCompanyCollection.find(
			company => company.id + '' === currentCompanyId + ''
		);

		const thereIsNoCompany = currentUserCompanyCollection.length < 1;
		const isANoSiderPage = this._shouldNotDisplayExpenseGroupSider(pathname) || thereIsNoCompany;
		const isANoSelectedCompanyPage = this._shouldShowSelectedCompany(pathname);

		return <React.Fragment>
			<Layout.Sider
				className="app-side-menu"
				collapsedWidth={50}
				collapsed={true}
			>
				<CompaniesNavigation
					isANoSelectedCompanyPage={isANoSelectedCompanyPage}
					setCurrentCompanyId={this._setCurrentCompany}
				/>
			</Layout.Sider>

			{!isANoSiderPage && <Layout.Sider
				className="app-side-menu"
				width={280}
				collapsedWidth={50}
				collapsed={currentMenu.get('isOpen')}
				style={{backgroundColor: '#333333'}}
			>
				<div
					className="menu-toggle-trigger"
					onClick={() => currentMenu.toggleState()}
				>
					<span id="layoutSider__title">
						{currentCompany && currentCompany.get('name')}
					</span>
					<Icon className="mark" type="left"/>
				</div>

				<ExpenseGroupsNavigation
					location={this.props.location}
				/>
			</Layout.Sider>}
		</React.Fragment>;
	}

	private _historyListen(location) {
		// const companiesRegex = /^\/companies\/(.*?)\//gm;
		// const matchCompanies = companiesRegex.exec(location.pathname);
		// const id = matchCompanies ? matchCompanies[1] : null;

		const {
			pathname,
		} = location;

		const reg = /\/companies\/(\d+)\/expense_groups\/(\d+)/;
		const res = pathname.match(reg);

		let companyId = null;
		let expenseGroupId = null;

		if (!!res) {
			companyId = res[1];
			expenseGroupId = res[2];

			if (!!companyId) {
				this._setCurrentCompany(companyId);
				if (!!expenseGroupId) {
					this._setExpenseGroupId(expenseGroupId);
				}
			}
		}
	}

	private _setCurrentCompany = id => {
		const {
			appStore,
		} = this.props;

		appStore.setCurrentCompanyId(id);
	}

	private _setExpenseGroupId = id => {
		const {
			appStore,
		} = this.props;

		appStore.setCurrentExpenseGroupId(id);
	}

	/**
	 * Check if we should display ExpenseGroupsNavigation
	 * Used to disable the sidebar when needed
	 *
	 * @param pathname
	 * @private
	 */
	private _shouldNotDisplayExpenseGroupSider(pathname) {
		const noSiderPagesRegexList = [
			/^\/settings\//,
			/\/expenses\/add/,
			/\/accounting-documents\//,
			/\/exports\//,
			/^\/$/,
		];

		const initialIsANoSiderPage = false;

		return noSiderPagesRegexList.reduce((isANoSiderPage: boolean, noSiderPageRegex: RegExp) => {
			return !!noSiderPageRegex.test(pathname) || isANoSiderPage;
		}, initialIsANoSiderPage);
	}

	private _shouldShowSelectedCompany(pathname) {
		const noSelectedCompanyPagesRegexList = [
			/^\/settings\//,
		];

		const initialIsANoSelectedCompanyPage = false;

		return noSelectedCompanyPagesRegexList.reduce((isANoSiderPage: boolean, noSiderPageRegex: RegExp) => {
			return !!noSiderPageRegex.test(pathname) || isANoSiderPage;
		}, initialIsANoSelectedCompanyPage);
	}
}

export default withRouter(LayoutSider);
