import {observable} from 'mobx';

import {SplitViewCollection} from 'modelx-jts-common';

import AbstractSplitViewStore from './AbstractSplitViewStore';
import ExpenseModel           from '../models/ExpenseModel';
import ExpenseIdModel    from '../models/ExpenseIdModel';
import UserApiCollection from '../models/collections/UserApiCollection';

class ExpenseSplitViewStore extends AbstractSplitViewStore {

	@observable public splitViewExpenseCollection = new SplitViewCollection(ExpenseModel, ExpenseIdModel);

	@observable public splitViewUserCollection = new UserApiCollection();

}

export default new ExpenseSplitViewStore();
