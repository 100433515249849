import {
	Collection,
	ConnectorResults,
}                                      from '@mathquis/modelx';
import { PlatformPrivateApiConnector } from './PlatformPrivateApiConnector';
import moment                          from 'moment';
import qs                              from 'qs';
import downloadFileFromReadableStream  from '../../modules/utils/downloadFileFromReadableStream';

export class PlatformApiCsvConnector extends PlatformPrivateApiConnector {

	public list(
		collection: Collection,
		options: {
			downloadFileName?: string,
			filters?: any;
			limit?: number,
			offset?: number,
			sorts?: object
		} = {}
	): any {
		const params: {
			itemsPerPage: number;
			page: number;
			pagination: boolean;
		} = { ...options.filters };

		params.pagination = !!(options.limit || options.offset);

		if (params.pagination) {
			if (options.limit) {
				params.itemsPerPage = Math.max(1, options.limit);
			}

			if (options.offset) {
				params.page = Math.floor(options.offset / Math.max(1, params.itemsPerPage)) + 1;
			}
		}

		if (options.sorts) {
			Object.keys(options.sorts).forEach(key => {
				// @ts-ignore
				params['order[' + key + ']'] = options.sorts[key] ? 'ASC' : 'DESC';
			});
		}

		return this
			.request(collection.path, {
				...options,
				method: 'get',
				params
			})
			.then((response: any) => {
				const dateString = moment().format('YYYYMMDD_HHmm_');

				downloadFileFromReadableStream(response, dateString + options.downloadFileName + '.csv');

				return new ConnectorResults([], response);
			})
			.catch((err) => console.error(err));
	}

	protected request(path: string, options: any = {}): any {
		return new Promise(async (resolve, reject) => {
			if (this.currentSession.isExpired) {
				try {
					await this.currentSession.refresh();
					resolve();
				} catch (err) {
					reject(err);
				}
			} else {
				resolve();
			}
		})
		.then(async () => {
			let url: string = this.options.baseURL + path;
			url += url.indexOf('?') >= 0 ? '' : '?';
			url += qs.stringify(options.params || {});

			const response = await fetch(url, {
				...options,
				headers: {
					...options.headers,
					'Accept': 'text/csv',
					'X-Platform-Authorization': 'Bearer ' + this.currentSession.token,
				}
			})

			if (!response.ok) {
				throw response;
			}

			return response;
		})
		.catch((err) => {
			this.onRequestError(err);
		});
	}
}
