import './AccountMenu.scss';

import React                     from 'react';
import { observer }              from 'mobx-react';
import Spin                      from 'antd/lib/spin';
import Trans                     from '../Trans';
import currentSession            from '../../currentSession';
import ModifyAccountInfoForm     from '../../modules/accounts/forms/ModifyAccountInfoForm';
import ModifyAccountPasswordForm from '../../modules/accounts/forms/ModifyAccountPasswordForm';
import ModifyAccountEmailForm    from '../../modules/accounts/forms/ModifyAccountEmailForm';
import ModifyAccountPhotoForm    from '../../modules/accounts/forms/ModifyAccountPhotoForm';
import editIcon                  from '../../../icons/edit.svg';
import keyIcon                   from '../../../icons/key.svg';
import mailIcon                  from '../../../icons/mail.svg';
import accountIcon               from '../../../icons/comptes.svg';
import {
	JtsDropdown,
	JtsActionWithModal,
	IconSvg,
	JtsFormMessage,
	MessageType,
}                                from 'react-jts-common';

@observer
export default class AccountMenu extends React.Component {

	public render() {
		const accountModel = currentSession.accountModel;

		return <div className="account-menu">
			{accountModel.isLoaded && accountModel.userMediaProfilePictureObjectUrl ? this.renderContent() : <Spin spinning/>}
		</div>;
	}

	private renderContent(): JSX.Element {
		const accountModel = currentSession.accountModel;

		return <JtsDropdown items={this.getActions()}>
			<div>
				<div
					className="avatar"
					style={{
						backgroundImage: accountModel.userMediaProfilePictureObjectUrl ? `url(${accountModel.userMediaProfilePictureObjectUrl})` : ''
					}}
				>
					{!accountModel.userMediaProfilePictureObjectUrl ? accountModel.fullNameFirstLetters : null}
				</div>
				<div className="user-name">
					<span className="user-name-actions">
						{accountModel.fullName}
					</span>
				</div>
			</div>
		</JtsDropdown>;
	}

	private getActions(): JSX.Element[] {
		const currentUser = currentSession.accountModel;

		return [
			// MODIFIER MES INFORMATIONS
			// requiredRights: [{uri: currentUser.get('@id'), action: 'PUT'}]
			<JtsActionWithModal
				actionProps={{
					title: <Trans id="Modifier mes informations"/>,
					leftComponent: <IconSvg svg={editIcon}/>,
					link: `/accounts/${currentUser.get('id')}/modify_info`
				}}
				modalProps={{
					title: <Trans id="Modifier mes informations de compte"/>,
					content: props => <ModifyAccountInfoForm {...props} model={currentUser}/>
				}}
			/>,

			// MODIFIER MON MOT DE PASSE
			// requiredRights: [{uri: currentUser.get('@id'), action: 'PUT'}]
			<JtsActionWithModal
				actionProps={{
					title: <Trans id="Modifier mon mot de passe"/>,
					leftComponent: <IconSvg svg={keyIcon}/>,
					link: `/accounts/${currentUser.get('id')}/modify_password`
				}}
				modalProps={{
					title: <Trans id="Modifier mon mot de passe"/>,
					content: props => <ModifyAccountPasswordForm
						{...props}
						model={currentUser}
						onSuccess={() => currentSession.realLogout()}
						headerComponent={<JtsFormMessage type={MessageType.Info} message={[
							'Cette action va forcer la déconnexion de votre compte. ' +
							'Vous pourrez ensuite vous connecter avec votre nouveau mot de passe'
						]}/>}
					/>
				}}
			/>,

			// MODIFIER MON EMAIL
			// requiredRights: [{uri: currentUser.get('@id'), action: 'PUT'}]
			<JtsActionWithModal
				actionProps={{
					title: <Trans id="Modifier mon adresse e-mail"/>,
					leftComponent: <IconSvg svg={mailIcon}/>,
					link: `/accounts/${currentUser.get('id')}/modify_email`
				}}
				modalProps={{
					title: <Trans id="Modifier mon adresse e-mail"/>,
					content: props => <ModifyAccountEmailForm
						{...props}
						model={currentUser}
						onSuccess={() => currentSession.realLogout()}
						headerComponent={<JtsFormMessage type={MessageType.Info} message={[
							'Cette action va forcer la déconnexion de votre compte. ' +
							'Vous pourrez ensuite vous connecter avec votre nouvelle adresse email'
						]}/>}
					/>
				}}
			/>,

			// MODIFIER MA PHOTO
			// requiredRights: [{uri: currentUser.get('@id'), action: 'PUT'}]
			<JtsActionWithModal
				actionProps={{
					title: <Trans id="Modifier ma photo"/>,
					leftComponent: <IconSvg svg={accountIcon}/>,
					link: `/accounts/${currentUser.get('id')}/modify_photo`
				}}
				modalProps={{
					title: <Trans id="Modifier ma photo de compte"/>,
					content: props => <ModifyAccountPhotoForm {...props} model={currentUser}/>
				}}
			/>
		];
	}
}
