import LsdApiModel from './global/LsdApiModel';
import { computed } from 'mobx';
import { currencySymbols } from '../tools/currencyTools';

export default class CurrencyModel extends LsdApiModel {
	static get path(): string {
		return '/currencies/{?id}';
	}

	@computed
	get code() {
		return this.get('code');
	}

	@computed
	get currencyName() {
		return this.get('name');
	}

	@computed
	get eurRate() {
		return this.get('eurRate');
	}

	get symbol() {
		return currencySymbols[this.get('code')];
	}
}
