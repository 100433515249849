import {i18nMark} from 'lingui-react';

export const CREDIT_CARD   = {
	label: i18nMark('Carte de crédit'),
	reference: 'CREDIT_CARD',
};

export const MONEY = {
	label:  i18nMark('Espèce'),
	reference: 'MONEY'
};

export const BANK_CHECK = {
	label:  i18nMark('Chèque bancaire'),
	reference: 'BANK_CHECK'
};

export const BANK_TRANSFER = {
	label:  i18nMark('Virement bancaire'),
	reference: 'BANK_TRANSFER'
};

export const AUTOMATIC_BANK_TRANSFER = {
	label:  i18nMark('Prélèvement automatique'),
	reference: 'AUTOMATIC_BANK_TRANSFER'
};

export const EXPENSE_REPORT = {
	label:  i18nMark('Note de frais'),
	reference: 'EXPENSE_REPORT'
};

export const COMPENSATION = {
	label: i18nMark('Compensation'),
	reference: 'COMPENSATION',
};

export const INTERBANK_PAYMENT = {
	label: i18nMark('TIP'),
	reference: 'INTERBANK_PAYMENT',
};

export const ORDER = {
	label: i18nMark('Mandat'),
	reference: 'ORDER',
};

export const PaymentMethodsReferences = {
	CREDIT_CARD,
	MONEY,
	BANK_CHECK,
	BANK_TRANSFER,
	AUTOMATIC_BANK_TRANSFER,
	EXPENSE_REPORT,
	COMPENSATION,
	INTERBANK_PAYMENT,
	ORDER,
};

export const getLabel = reference => {
	if (!!PaymentMethodsReferences[reference]) {
		return PaymentMethodsReferences[reference].label;
	} else {
		return reference;
	}
};

export default Object.values(PaymentMethodsReferences);
