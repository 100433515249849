import LsdApiModel      from './global/LsdApiModel';
import { computed }     from 'mobx';
import { getIdFromUrn } from '../tools/lsdTools';

export default class ExpenseSupplierContactModel extends LsdApiModel {
	static get path(): string {
		return '/expense_supplier_contacts/{?id}';
	}

	@computed
	public get expenseId() {
		return this.get('expense.id');
	}

	@computed
	public get supplierId() {
		return this.get('supplier.id');
	}

	@computed
	public get supplierUrn() {
		return this.get('supplier.@urn');
	}

	@computed
	public get entityUrn() {
		return this.get('entityUrn');
	}

	@computed
	public get entityId() {
		return getIdFromUrn(this.entityUrn);
	}

	@computed
	public get userUrn() {
		return this.get('userUrn');
	}

	@computed
	public get userId() {
		return getIdFromUrn(this.userUrn);
	}
}
