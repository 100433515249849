import AccountLsdModel             from './AccountLsdModel';
import UserModel                   from './fondationsModels/UserModel';
import axios                       from 'axios';
import CompanyCookie               from './CompanyCookie';
import ConfigProxy                 from '../tools/ConfigProxy';
import currentModelXMercureService from '../currentModelXMercureService';
import GroupLsdModel               from './GroupLsdModel';
import i18n                        from 'components/i18n';
import jwtDecode                   from 'jwt-decode';
import MeModel                     from './MeModel';
import MercureTokenModel           from './MercureTokenModel';
import notification                from 'antd/lib/notification';
import qs                          from 'qs';
import Raven                       from 'raven-js';
import SessionUser                 from './SessionUser';
import store                       from '../store';
import {
	action,
	computed,
	observable,
}                                  from 'mobx';
import { ApiCollection }           from 'modelx-jts-common';
import { getIriFromId }            from 'tools/lsdTools';
import { Model }                   from '@mathquis/modelx';
import { Platform }                from 'proto/Service/Fondations/UserRights_pb';
import UserRights = Platform.Service.Fondations.UserRights;

const WITH_REDIRECTION_TO_COMPANY = true;
const WITHOUT_REDIRECTION_TO_COMPANY = false;

// import { Platform } from 'proto/proto';
// import UserRights = Platform.Service.Fondations.UserRights;

// import * as Proto from 'proto/Service/Fondations/UserRights_pb';
// const UserRights = Proto.UserRights;

const client = axios.create({
	baseURL: ConfigProxy.getServiceConfig('fondations', 'api_endpoint'),
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/x-www-form-urlencoded'
	},
	responseType: 'json'
});

export default class Session extends Model {

	public accountModel = new UserModel();
	public companyCookie = new CompanyCookie();
	public lsdGroups = new ApiCollection(GroupLsdModel);
	public me = new MeModel();
	public protoUserRights = new UserRights();
	public user = new SessionUser();

	@observable
	public _loggedIn = false;

	@observable
	public _processing = true;

	@observable
	public _refreshing = false;

	@computed
	public get isProcessing(): boolean {
		return this._processing;
	}

	@computed
	public get isRefreshing(): boolean {
		return this._refreshing;
	}

	@computed
	public get isLoggedIn(): boolean {
		return this._loggedIn;
	}

	@computed
	public get isExpired(): boolean {
		return this.user.get('user_data.exp', 0) < Math.ceil(new Date().valueOf() / 1000);
	}

	@computed
	public get isAdmin(): boolean {
		return !!this.user && this.user.isAdmin();
	}

	@computed
	public get isManager(): boolean {
		return !!this.user && this.user.isManager();
	}

	@computed
	public get isSuperAdmin(): boolean {
		return !!this.user && this.user.isSuperAdmin();
	}

	@computed
	public get userUri() {
		const currentUserId = this.accountModel.id;
		return getIriFromId(AccountLsdModel, currentUserId);
	}

	public async login(username: string, password: string) {
		this.setIsProcessing(true);

		await this.user
			.set({
				id: 'current',
				user_data: {
					user_informations: {
						avatar: '',
						firstName: '',
						lastName: ''
					}
				},
				refreshToken: '',
				token: '',
			})
			.save();

		return client('/login_check', {
			method: 'post',
			data: qs.stringify({
				_username: username,
				_password: password,
				from: 1 // TODO: Use the correct value otherwise it does not work if missing
			})
		})
			.then((response) => {
				return this.onLoggedIn(response, WITH_REDIRECTION_TO_COMPANY);
			})
			.finally(
				() => this.setIsProcessing(false)
			);
	}

	public refresh(): Promise<void> | void {
		if (this._refreshing) {
			return;
		}

		this._refreshing = true;

		return client('/token/refresh', {
				method: 'post',
				data: {
					refresh_token: this.user.get('refreshToken'),
					from: 1,
				},
			})
			.then(async response => {
				await this.onLoggedIn(response);
			})
			.catch(() => {
				this._refreshing = false;
				this.realLogout();
			});
	}

	public realLogout() {
		this.clear();
		// this.destroy();

		store.appStore.clearStore();
		store.organigramStore.clearStore();

		this.setLoggedIn(false)
		this.setIsProcessing(false);

		this.user.clear();
		this.user.destroy();

		this.protoUserRights = new UserRights();

		this.accountModel.clear();
		this.me.clear();
		this.lsdGroups.clear();

		Raven.setUserContext({});
	}

	public logout() {
		// call each time there is a 401
		// whereas we want it to be call only when refresh fail
		// see realLogout method
	}

	public get token() {
		return this.user.get('token');
	}

	public get locale() {
		return 'fr';
	}

	public getRoleUrnArr(): string[] {
		if (this.protoUserRights) {
			return this.protoUserRights.roles.map(r => {
				const urn = r.urn;

				if (!urn) {
					return '';
				}

				return `${urn.partition}:${urn.service}:${urn.resource}:${urn.identifier}`;
			});
		}

		return [];
	}

	@action
	public setIsProcessing(value: boolean) {
		this._processing = value;
	}

	@action
	protected async onLoggedIn(response, companyRedirection = WITHOUT_REDIRECTION_TO_COMPANY) {
		this.user
			.set({
				user_data: jwtDecode(response.data.token),
				token: response.data.token,
				refreshToken: response.data.refresh_token,
			});

		await this.user.save();

		try {
			this._updateProtoUserRights();

			const roleUrnArr = this.getRoleUrnArr();

			// Seul un user lsd peut accéder à LSD
			if (
				!roleUrnArr.includes('jts:lsd:role:ROLE_LSD_USER') &&
				!roleUrnArr.includes('jts:lsd:role:ROLE_LSD_MANAGER') &&
				!roleUrnArr.includes('jts:lsd:role:ROLE_LSD_ADMIN') &&
				!roleUrnArr.includes('jts:lsd:role:ROLE_LSD_SUPER_ADMIN')
			) {
				throw new Error();
			}

			await this
				.init(companyRedirection)
				.catch(() => {
					// just catch the error when this has not been previously created
				});

			this.setLoggedIn(true);

			Raven.setUserContext({
				id: this.accountModel.id,
				email: this.accountModel.get('email'),
				username: this.accountModel.get('username')
			});
		} catch (err) {
			notification.error({
				description: '',
				message: i18n._('Vous ne disposez pas des autorisations requises pour accéder à cette interface'),
				className: 'app-notification error'
			});

			this.realLogout();
		}

		this._refreshing = false;

		return true;
	}

	public async internalInitialize(options = {}) {
		this._setProcessing(true);

		this.user = new SessionUser();

		try {
			await this.user.fetch();

			this._updateProtoUserRights();

			if (this.user.get('token') && this.user.get('id')) {
				await this.init().catch(() => {
					// just catch the error
				});

				this.setLoggedIn(true);

				Raven.setUserContext({
					id: this.accountModel.id,
					email: this.accountModel.get('email'),
					username: this.accountModel.get('username')
				});
			}
		} catch (err) {
			// just catch the error if user doesn't exist
			console.error(err);
		}

		this._setProcessing(false);
	}

	protected async init(companyRedirection = WITHOUT_REDIRECTION_TO_COMPANY): Promise<any> {
		const promises: Promise<any>[] = [];

		this.accountModel = new UserModel();
		this.accountModel.set({
			id: this.protoUserRights.urn?.identifier
		});
		promises.push(this.accountModel.fetch());

		this.companyCookie = new CompanyCookie();
		this.companyCookie.set({ id: this.accountModel.id });

		promises.push(
			this.companyCookie
				.fetch()
				.then(() => {
					if (companyRedirection && this.companyCookie.get('company')) {
						window.location.href = `/companies/${this.companyCookie.get('company')}/my-tasks`;
					}

					return true;
				})
				.catch(() => {
					// just catch error if not found
				})
		);

		this.lsdGroups = new ApiCollection(GroupLsdModel);
		promises.push(this.lsdGroups.list());

		this.me = new MeModel();
		promises.push(
			this.me
				.fetch()
				.then(() => {
					if (this.me.id) {
						store.appStore.loadAllCurrentUserAndMenuData(this.me.id);
					}

					return true;
				})
		);

		// Récupération du token mercure
		promises.push(
			new MercureTokenModel()
				.fetch()
				.then(mercureModel => {
					currentModelXMercureService.setToken(mercureModel.get('token'));
					return true;
				})
		);

		return Promise.all(promises);
	}

	@action
	private _setProcessing(value: boolean) {
		this._processing = value;
	}

	@action
	private setLoggedIn(value: boolean) {
		this._loggedIn = value;
	}

	private _updateProtoUserRights() {
		// this.protoUserRights = UserRights.deserializeBinary(
		this.protoUserRights = UserRights.decode(
			new TextEncoder().encode(this.user.get(['user_data', 'platform.token.owner']))
		);
	}
}
