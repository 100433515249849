import LsdApiModel from './global/LsdApiModel';
import { computed } from 'mobx';

export default class CompanyPaymentVoucherStepModel extends LsdApiModel {
	static get path(): string {
		return '/company_payment_voucher_steps/{?id}';
	}

	@computed
	get paymentVoucherStepId() {
		return this.get('paymentVoucherStep.id');
	}

	@computed
	get paymentVoucherStepName() {
		return this.get('paymentVoucherStep.name');
	}

	@computed
	get paymentVoucherStepObligatory() {
		return this.get('paymentVoucherStep.obligatory');
	}

	@computed
	get paymentVoucherStepReference() {
		return this.get('paymentVoucherStep.reference');
	}

	@computed
	get enabled() {
		return this.get('enabled');
	}
}
