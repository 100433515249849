import UserModel   from '../models/fondationsModels/UserModel';
import EntityModel from '../models/fondationsModels/EntityModel';
import SupplierModel      from '../models/SupplierModel';
import UserUserGroupModel from '../models/fondationsModels/UserUserGroupModel';

export default {
	'jts:fondations:entity': EntityModel,
	'jts:fondations:user': UserModel,
	'jts:fondations:user_user_group': UserUserGroupModel,
	'jts:lsd:supplier': SupplierModel,
};
