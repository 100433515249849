import { Model } from '@mathquis/modelx';
import { toJS }  from 'mobx';

export interface IResolvableOptions {
	// idProperty: string; // TO IMPLEMENT LATER
	urnPropertyName: string;
}

export default function(
	options: IResolvableOptions,
): (
	target: Model,
	propertyKey: string
) => void {
	return (target: Model, propertyKey: string) => {
		// console.log('resolvable', 'modelClass', modelClass);
		// console.log('resolvable', 'target', target);
		// console.log('resolvable', 'propertyKey', propertyKey);

		const model = target[propertyKey];
		// console.log('resolvable', 'model', model, model?.attributes);

		function getter(): any {
			let subModel = this.get(propertyKey);
			// console.log('resolvable', 'subModel 1', subModel, subModel?.attributes);

			const urnPropertyValue = toJS(this.get(options.urnPropertyName)) || '';
			// console.log('resolvable', 'urnPropertyValue', urnPropertyValue);
			const [, , , id] = urnPropertyValue.split(':');
			// console.log('resolvable', 'id', id);

			if (!subModel) {
				subModel = Object.create(model);
				this.set(propertyKey, subModel);
			}

			if (subModel.id !== id) {
				subModel.setId(id);
			}

			// console.log('resolvable', 'subModel 2', subModel, subModel?.attributes);

			// console.log('resolvable', 'before model.fetch()', subModel.isLoaded, subModel.isLoading);

			if (
				subModel
				&& !subModel.isLoaded
				&& !subModel.isLoading
			) {
				// console.log('resolvable', 'inside subModel.fetch()');
				subModel.fetch();
			}

			return subModel;
		}

		function setter(value) {
			// model = value;
			// this.set(value);
			// this[propertyKey] = value;
			this.set(propertyKey, value);
		}

		Object.defineProperty(target, propertyKey, {
			get: getter,
			set: setter,
		});

		// DON'T WORK, DON'T KNOW WHY
		// computed(target, propertyKey);
	};
}
