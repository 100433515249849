import { Model, ConnectorResult }      from '@mathquis/modelx';
import ConfigProxy                     from '../tools/ConfigProxy';
import { PlatformFileUploadConnector } from './connectors/PlatformFileUploadConnector';

export const bankDetailMediaUploadConnector = new PlatformFileUploadConnector({
	baseURL: ConfigProxy.getServiceConfig('lsd', 'api_endpoint'),
});

export default class BankDetailMediaUploadModel extends Model {
	static get path(): string {
		return '/bank_detail_media';
	}

	static get connector() {
		return bankDetailMediaUploadConnector as any;
	}

	// @ts-ignore
	public save(options: object = {}) {
		return new Promise((resolve, reject) => {
			const formData = new FormData();

			formData.set('file', this.get('file'));

			return this.connector
				.save(this, {
					...options,
					patchAttributes: formData,
				})
				.then((result: ConnectorResult) => {
					this.onSaveSuccess(result, options);
					resolve(this);
				})
				.catch((err) => reject(err));
		});
	}
}
