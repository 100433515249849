import organigramCollectionStore from './OrganigramCollectionStore';
import appStore                  from './AppStore';
import { ApiCollection }         from 'modelx-jts-common';
import {
	action,
	computed,
	observable,
}                                from 'mobx';
import ExpenseBudgetModel        from '../models/ExpenseBudgetModel';
import BudgetModel               from '../models/BudgetModel';

export class OrganigramEntityDashboardStore {
	public expenseCollection = new ApiCollection(ExpenseBudgetModel);

	@observable
	private _currentModel: BudgetModel;

	@computed
	public get parents() {
		return organigramCollectionStore.isLoading ? {} : organigramCollectionStore.getModelParents(this._currentModel);
	}

	@computed
	public get currentModel() {
		return this._currentModel;
	}

	@computed
	public get categories() {
		return this._currentModel ? organigramCollectionStore
			.getAllChildrenWithType(this._currentModel, 'ProjectCategory') : [];
	}

	@computed
	public get subCategories() {
		return this._currentModel ? organigramCollectionStore
			.getAllChildrenWithType(this._currentModel, 'ProjectSubCategory') : [];
	}

	@computed
	public get projects() {
		return this._currentModel ? organigramCollectionStore
			.getAllChildrenWithType(this._currentModel, 'Project') : [];
	}

	@action
	public setCurrentModel(model: BudgetModel) {
		this._currentModel = model;

		const filters = {
			ProjectPole: 'project.projectSubCategory.projectCategory.projectPole',
			ProjectCategory: 'project.projectSubCategory.projectCategory',
			ProjectSubCategory: 'project.projectSubCategory',
			Project: 'project'
		};

		this.expenseCollection
			.setFilter(filters[model.get('@type')], model.id)
			.setFilter('project.expenseGroup', appStore.currentExpenseGroupId);
	}
}

export default new OrganigramEntityDashboardStore();
