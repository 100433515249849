import CompanyModel      from '../models/CompanyModel';
import { ApiCollection } from 'modelx-jts-common';
import { hierarchy }     from '../constants/RolesHierarchy';

export const getCompanyIdFromIri = (iri: string): number => {
	const path = `/companies/`;
	const regex = new RegExp( path + '(\\d+)', 'gi');
	const res = regex.exec(iri);

	if (!!res && !!res[1]) {
		return parseInt(res[1], 0.10);
	}

	return null;
};

export const getIriFromId = (ModelConstructor, id: number) => {
	const model = new ModelConstructor({ id, });
	const uri = `/lsd${model.path}`;
	return uri;
};

export const getIdFromIri = (ModelConstructor, iri: string) => {
	const modelPath = `/lsd${ModelConstructor.path}`;
	const pattern = /(.+){.+}/;
	const found = modelPath.match(pattern);

	if (!!found[1]) {
		return iri.replace(found[1], '');
	}

	return null;
};

export const getIdFromUrn = (urn: string | null | undefined) => {
	if (typeof urn !== 'string') {
		return undefined;
	}

	const res = urn.match(/\w:\w+:\w+:(\d+)/m);

	return res ? res[1] : undefined;
};

export const topGroup = (userGroupCompanyCollection: ApiCollection, company: CompanyModel) => {
	const userGroupsInCompany = userGroupCompanyCollection.map(ugc  => {
		if (ugc.get('company.@id') === getIriFromId(CompanyModel, company.id)) {
			return ugc;
		}
	});

	const reducer = (previousGroup, nextGroup) => {
		if (!previousGroup) {
			return nextGroup || null;
		}

		if (!!nextGroup && !!hierarchy[nextGroup.get('reference')]
			&& hierarchy[nextGroup.get('reference')][previousGroup.get('reference')]) {
			return nextGroup;
		}
		return previousGroup;
	};

	const reducedArray = userGroupsInCompany.reduce(reducer, null);

	return reducedArray;
};

export default {
	getCompanyIdFromIri,
	getIriFromId,
	getIdFromIri,
};
