import i18n from '../components/i18n';

const NOT_PAID = {
	reference: 'NOT_PAID',
	label: i18n._('Non-payée'),
	color: '#E67E22'
};

const PARTIALLY_PAID = {
	reference: 'PARTIALLY_PAID',
	label: i18n._('Payée partiellement'),
	color: '#3777BC'
};

const PAID = {
	reference: 'PAID',
	label: i18n._('Totalement payée'),
	color: '#3FB05D'
};

const references = {
	NOT_PAID,
	PARTIALLY_PAID,
	PAID,
};

export const allStatus = [
	NOT_PAID,
	PARTIALLY_PAID,
	PAID,
];

export const getLabel = reference => {
	if (!!references[reference]) {
		return references[reference].label;
	} else {
		return reference;
	}
};

export const expensePaymentStatusIsNotPaid = reference => reference === NOT_PAID.reference;

export const expensePaymentStatusIsCancelable = reference => {
	return !!([
		NOT_PAID,
	].find(ref => ref.reference === reference));
};

export default references;
