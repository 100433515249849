import { ApiCollection }          from 'modelx-jts-common';
import AccountLsdModel            from '../AccountLsdModel';
import UserModel                  from '../fondationsModels/UserModel';
import { getUniqueDefinedValues } from '../../tools/jsTools';

export default class UserApiCollection extends ApiCollection {

	public fondationsUserCollection: ApiCollection;

	constructor() {
		super(AccountLsdModel);

		this.fondationsUserCollection = new ApiCollection(UserModel);
	}

	public list(options?: object): Promise<Collection> {
		return new Promise(async (resolve, reject) => {
			try {
				await super.list(options);

				const usersIds = getUniqueDefinedValues(
					this.map((account: AccountLsdModel) => account.userId)
				);

				// const fondationsUsersIds = this.map(user => {
				// 	const arr = user.get('userUrn', '').split('.');
				//
				// 	if (arr && arr.length) {
				// 		const id = arr.pop();
				// 		return id;
				// 	}
				// });

				await this.fondationsUserCollection
					.addSort('firstName', true)
					.addSort('lastName', true)
					.setFilter('id', usersIds)
					.list();

				// const fondationsUsersModels = this.fondationsUserCollection.models.slice();

				this.forEach((account: AccountLsdModel) => {
					account.isLoaded = false;

					const user = this.fondationsUserCollection.find((u: UserModel) => u.get('@urn') === account.userUrn) as UserModel;

					if (user) {
						account.setUser(user);
					}

					// const entityIndex = fondationsUsersModels.findIndex(fondationsUser => {
					// 	return fondationsUser.get('@urn') === account.get('userUrn');
					// });
					//
					// if (entityIndex > -1) {
					// 	account.setUser(fondationsUsersModels[entityIndex]);
					// }

					account.isLoaded = true;
				});

				resolve(this);
			} catch (err) {
				reject(err);
			}
		});
	}
}
