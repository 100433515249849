import { computed }             from 'mobx';
import { Model }                from '@mathquis/modelx';
import ConfigProxy                     from '../../tools/ConfigProxy';
import { PlatformPrivateApiConnector } from '../connectors/PlatformPrivateApiConnector';
import connectorWithCache              from '../decorators/connectorWithCache';

export const fondationsConnector = new (connectorWithCache(PlatformPrivateApiConnector))({
	baseURL: ConfigProxy.getServiceConfig('fondations', 'api_endpoint'),
});

export default class FondationsApiModel extends Model {
	static get connector() {
		return fondationsConnector;
	}

	public patch(attributes: object = {}, options: object = {}) {
		return this.save({...options, patchAttributes: attributes});
	}

	@computed
	public get key() {
		return this.constructor.name + '-' + this.id;
	}

	@computed
	public get pageUrl() {
		return this.path;
	}

	@computed
	public get id(): number {
		return this.get('id');
	}

	@computed
	public get iri(): string {
		return this.get('@id');
	}

	@computed
	public get urn(): string {
		return this.get('@urn');
	}

	@computed
	public get isEmpty(): boolean {
		return !this.get('@type');
	}
}
