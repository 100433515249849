import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class ExpensePaymentVoucherStepModel extends LsdApiModel {
	static get path(): string {
		return '/expense_payment_voucher_steps/{?id}';
	}

	@computed
	public get expensePaymentVoucherId() {
		return this.get('expensePaymentVoucher.id');
	}
}
