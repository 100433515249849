import React                        from 'react';
import Row                          from 'antd/lib/row';
import Col                          from 'antd/lib/col';
import Form                         from 'antd/lib/form';
import portraitImgExp               from '../images/portrait.jpg';
import maskImg                      from '../images/mask.png';
import UserModel                    from '../../../models/fondationsModels/UserModel';
import Trans                        from '../../../components/Trans';
import JtsImageUpload               from '../../../components/Form/JtsImageUpload';
import {
	IJtsFormProps,
	JtsForm,
}                                   from 'react-jts-common';
import UserMediaProfilePictureModel from '../../../models/UserMediaProfilePictureModel';

export interface IModifyAccountPhotoFormProps extends IJtsFormProps {
	model: UserModel;
}

class ModifyAccountPhotoForm extends React.Component<IModifyAccountPhotoFormProps> {

	public async componentDidMount() {
		const {
			form,
			model,
		} = this.props;

		form.setFieldsValue({
			profilePicture: model.get('userMediaProfilePicture.@id')
		});
	}

	public render() {
		const {
			form,
			model,
		} = this.props;

		const {
			getFieldDecorator,
		} = form;

		return <JtsForm
			{...this.props}
			onSubmit={params => this._handleSubmit(params)}
			mapParams={values => ({ userMediaProfilePicture: values.file })}
			notificationSuccessMessage={<Trans id="La photo du compte a été bien modifiée." />}
			notificationErrorMessage={<Trans id="La photo du compte n'a pas été modifiée." />}
		>
			<Row gutter={20} type="flex" align="top">
				<Col xs={24} md={24}>
					<Form.Item>
						{getFieldDecorator('file')(
							<JtsImageUpload
								aspectRatio={3 / 4}
								description={<Trans id="Format portrait 3 / 4" />}
								example={portraitImgExp}
								fileUri={model.userMediaProfilePictureObjectUrl}
								form={form}
								label={<Trans id="Photo du compte" />}
								mask={maskImg}
								model={UserMediaProfilePictureModel}
								takePhoto
							/>
						)}
					</Form.Item>
				</Col>
			</Row>
		</JtsForm>;
	}

	private _handleSubmit = (params) => {
		const { model } = this.props;
		return model.patch(params);
	}
}

export default Form.create<IModifyAccountPhotoFormProps>()(ModifyAccountPhotoForm);
