import './LoginRequiredComponent.scss';

import React                  from 'react';
import { observer }           from 'mobx-react';
import Spin                   from 'antd/lib/spin';
import AppLayout              from './Layout/Layout';
import currentSession         from '../currentSession';
import LoginScreen            from '../modules/login/screens/LoginScreen';
import AppStoreAwareComponent from './AppStoreAwareComponent';

const styles = {
	spin: {
		alignItems: 'center',
		backgroundColor: '#f2f2f2',
		display: 'flex',
		flex: 1,
		justifyContent: 'center',
	}
};

@observer
class LoginRequiredComponent extends React.Component {
	private _isFirstRender: boolean = true;

	public componentDidUpdate() {
		this._isFirstRender = false;
	}

	public render() {
		if (currentSession.isRefreshing) {
			return <div className="loginRequiredComponent__refreshingContainer">
				<Spin spinning />
			</div>;
		}

		if (!currentSession.isProcessing && currentSession.isLoggedIn) {
			return <AppLayout {...this.props} />;
		}

		if (!currentSession.isProcessing && currentSession.isLoggedIn) {
			return <AppStoreAwareComponent {...this.props}>
				<AppLayout {...this.props} />
			</AppStoreAwareComponent>;
		}

		if (this._isFirstRender && currentSession.isProcessing && !currentSession.isLoggedIn) {
			return <Spin
				spinning={true}
				style={styles.spin}
				size="large"
			/>;
		}

		return <LoginScreen {...this.props} />;
	}
}

export default LoginRequiredComponent;
