import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class ProjectModel extends LsdApiModel {
	static get path(): string {
		return '/projects/{?id}';
	}

	@computed
	get expenseGroupId() {
		return this.get('expenseGroup.id');
	}

	@computed
	get projectSubCategoryId() {
		return this.get('projectSubCategory.id');
	}

	@computed
	get projectSubCategoryIri() {
		return this.get('projectSubCategory.@id');
	}

	@computed
	get title() {
		return this.get('title');
	}
}
