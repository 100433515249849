import LsdApiModel                 from './global/LsdApiModel';
import { computed }                from 'mobx';
import { getLabel, getLabelShort } from '../constants/AccountingDocumentTypes';
import { PAID }                    from '../constants/AccountingDocumentStatuses';
import { PARTIALLY_PAID }          from '../constants/AccountingDocumentStatuses';
import { REGISTERED }              from '../constants/AccountingDocumentStates';

export default class AccountingDocumentModel extends LsdApiModel {
	static get path(): string {
		return '/accounting_documents/{?id}';
	}

	@computed
	public get accountingDocumentMediaObjectUrl() {
		return this.get('accountingDocumentMedia.objectUrl');
	}

	@computed
	public get accountingDocumentName(): string {
		if (!this.type || !this.number) {
			return '';
		}

		return `${this.typeLabel} ${this.number ? this.number : this.accountingDocumentMediaMediaFileName}`;
	}

	@computed
	public get accountingDocumentMedia(): string {
		return this.get('accountingDocumentMedia');
	}

	@computed
	public get accountingDocumentMediaMediaFileName(): string {
		return this.get('accountingDocumentMedia.mediaFileName');
	}

	@computed
	public get accountingDocumentType(): string {
		return getLabel(this.get('type'));
	}

	@computed
	public get accountingDocumentTypeShort(): string {
		return getLabelShort(this.get('type'));
	}

	@computed
	public get amount() {
		return this.get('amount');
	}

	@computed
	public get amountInCompanyCurrency() {
		return this.get('amountInCompanyCurrency');
	}

	@computed
	public get closed() {
		return this.get('closed');
	}

	@computed
	public get comment() {
		return this.get('comment');
	}

	@computed
	public get companyId() {
		return this.get('company.id');
	}

	@computed
	public get currencyId() {
		return this.get('currency.id');
	}

	@computed
	public get currencyIri(): string {
		return this.get('currency.@id');
	}

	@computed
	public get isPaid() {
		return this.status === PAID.reference;
	}

	@computed
	public get isPartiallyPaid() {
		return this.status === PARTIALLY_PAID.reference;
	}

	@computed
	public get isRegistered() {
		return this.get('state') === REGISTERED.value;
	}

	@computed
	public get number(): string {
		return this.get('number');
	}

	@computed
	public get status() {
		return this.get('status');
	}

	@computed
	public get supplierId() {
		return this.get('supplier.id');
	}

	@computed
	public get supplierUrn() {
		return this.get('supplier.@urn');
	}

	@computed
	public get type(): string {
		return this.get('type');
	}

	@computed
	public get typeLabel(): string {
		return getLabel(this.type);
	}

	@computed
	public get typeLabelShort(): string {
		return getLabelShort(this.type);
	}

	@computed
	public get managedByIri(): string {
		return this.get('managedBy.@id');
	}
}
