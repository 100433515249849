import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class ProjectSubCategoryModel extends LsdApiModel {
	static get path(): string {
		return '/project_sub_categories/{?id}';
	}

	@computed
	get projectCategoryId() {
		return this.get('projectCategory.id');
	}

	@computed
	get title() {
		return this.get('title');
	}
}
