import LsdApiModel        from './global/LsdApiModel';
import { computed, toJS } from 'mobx';
import moment             from 'moment';

export default class AccountingDocumentExpenseModel extends LsdApiModel {
	static get path(): string {
		return '/accounting_document_expenses/{?id}';
	}

	@computed
	get accountingDocumentId() {
		return this.get('accountingDocument.id');
	}

	@computed
	get accountingDocumentIri() {
		return this.get('accountingDocument.@id');
	}

	@computed
	get amount() {
		return this.get('amount');
	}

	@computed
	get amountInCompanyCurrency() {
		return this.get('amountInCompanyCurrency');
	}

	@computed
	get createdAt() {
		return this.get('createdAt');
	}

	@computed
	get createdAtMoment() {
		return moment(this.createdAt);
	}

	@computed
	get createdByIri() {
		return this.get('createdBy.@id');
	}

	@computed
	get expensePaymentVouchers(): { id: number; '@id': string; '@type': string; }[] {
		return toJS(this.get('expensePaymentVouchers', []));
	}

	@computed
	get expensePaymentVouchersIris(): string[] {
		return this.expensePaymentVouchers.map(d => d['@id']);
	}
}
