import { computed }            from 'mobx';
import appStore                from '../store/AppStore';
import LsdApiModel             from './global/LsdApiModel';
import ProjectPoleModel        from './ProjectPoleModel';
import ProjectCategoryModel    from './ProjectCategoryModel';
import ProjectSubCategoryModel from './ProjectSubCategoryModel';

export default class BudgetModel extends LsdApiModel {
	@computed
	public get estimatedBudget(): number {
		return this.get('budgetToValidate.amount', 0);
	}

	@computed
	public get nbItemsEstimatedBudget(): number {
		return this.get('budgetToValidate.nbItems', 0) + this.get('budgetValidated.nbItems', 0);
	}

	@computed
	public get realBudget(): number {
		return this.get('budgetToPay.amount')
			+ this.get('budgetPaid.amount');
	}

	@computed
	public get nbItemsRealBudget(): number {
		return this.get('budgetToPay.nbItems', 0) + this.get('budgetPaid.nbItems', 0);
	}

	@computed
	public get targetBudget(): number | null {
		return this.getTargetBudget(appStore.currentExpenseGroupId);
	}

	@computed
	public get isExceededBudgetTarget(): boolean {
		return this.estimatedBudget > this.targetBudget;
	}

	@computed
	public get isExceededBudgetTargetReal(): boolean {
		return this.realBudget > this.targetBudget;
	}

	@computed
	public get defaultModel(): LsdApiModel | null {
		const dictionary = {
			ProjectPole: ProjectPoleModel,
			ProjectCategory: ProjectCategoryModel,
			ProjectSubCategory: ProjectSubCategoryModel
		};

		if (dictionary[this.get('@type')]) {
			return new dictionary[this.get('@type')]({id: this.id});
		}

		return null;
	}

	public getTargetBudget(expenseGroupId: number): number | null {
		return this.get(`budgetTargets.${expenseGroupId}.amount`, null);
	}
}
