import { IModelOptions }  from '@mathquis/modelx/lib/types/model';
import { IListenOptions } from 'modelx-jts-common';
import { Model }          from '@mathquis/modelx';

export abstract class AbstractMercureModel extends Model {
	protected abstract service;

	private readonly _mercureKey: string;

	constructor(attributes?: any, options?: IModelOptions) {
		super(attributes, options);

		this._mercureKey = (new Date().getTime() + Math.floor((Math.random() * 10000) + 1)).toString(16);
	}

	public get mercureKey() {
		return this._mercureKey;
	}

	public listenForFetch(options: IListenOptions = {}) {
		this.service.listenForFetchModel(this, options);

		return this;
	}

	public stopListenForFetch() {
		this.service.stopListenForFetch(this);
	}
}
