import LsdApiModel from './global/LsdApiModel';
import { computed } from 'mobx';

export default class VatModel extends LsdApiModel {
	static get path(): string {
		return '/vats/{?id}';
	}

	@computed
	public get defaultRate() {
		return this.get('defaultRate');
	}

	@computed
	public get rate() {
		return this.get('rate');
	}
}
