import {observable} from 'mobx';

import {AbstractStore, ApiCollection, PagedCollection, SplitViewCollection} from 'modelx-jts-common';

import AccountingDocumentIdModel  from '../models/AccountingDocumentIdModel';
import SupplierApiCollection      from '../models/collections/SupplierApiCollection';
import AccountingDocumentVatModel from '../models/AccountingDocumentVatModel';
import ExpensePaymentVoucherModel      from '../models/ExpensePaymentVoucherModel';
import AccountingDocumentDeadlineModel from '../models/AccountingDocumentDeadlineModel';
import AccountingDocumentHistoryModel from '../models/AccountingDocumentHistoryModel';
import ExpenseBudgetModel             from '../models/ExpenseBudgetModel';
import AccountingDocumentExpenseModel from '../models/AccountingDocumentExpenseModel';
import ProjectModel                   from '../models/ProjectModel';
import ProjectSubCategoryModel        from '../models/ProjectSubCategoryModel';
import ProjectCategoryModel           from '../models/ProjectCategoryModel';
import ProjectPoleModel               from '../models/ProjectPoleModel';
import AccountingDocumentBudgetModel  from '../models/AccountingDocumentBudgetModel';
import ExpenseGroupModel              from '../models/ExpenseGroupModel';

class AccountingDocumentsSplitViewStore extends AbstractStore {

	@observable
	public currentAccountingDocument = new AccountingDocumentBudgetModel();

	@observable
	public splitViewCollection = new SplitViewCollection(AccountingDocumentBudgetModel, AccountingDocumentIdModel);

	@observable
	public supplierCollection = new SupplierApiCollection();

	@observable
	public accountingDocumentAccountingDocumentExpenseCollection = new ApiCollection(AccountingDocumentExpenseModel);

	@observable
	public accountingDocumentDeadlineCollection = new ApiCollection(AccountingDocumentDeadlineModel);

	@observable
	public accountingDocumentExpenseCollection = new ApiCollection(ExpenseBudgetModel);

	@observable
	public accountingDocumentHistoryCollection = new PagedCollection(AccountingDocumentHistoryModel);

	@observable
	public accountingDocumentVatCollection = new ApiCollection(AccountingDocumentVatModel);

	@observable
	public accountingDocumentVoucherCollection = new ApiCollection(ExpensePaymentVoucherModel);

	public expenseCollection = new ApiCollection(ExpenseBudgetModel);
	public voucherCollection = new ApiCollection(ExpensePaymentVoucherModel);
	public projectCollection = new ApiCollection(ProjectModel);
	public subCategoryCollection = new ApiCollection(ProjectSubCategoryModel);
	public categoryCollection = new ApiCollection(ProjectCategoryModel);
	public poleCollection = new ApiCollection(ProjectPoleModel);
	public expenseGroupCollection = new ApiCollection(ExpenseGroupModel);

	constructor() {
		super();

		this.setCurrentAccountingDocument = this.setCurrentAccountingDocument.bind(this);
	}

	public setCurrentAccountingDocument(accountingDocument: AccountingDocumentBudgetModel): Promise<any> {
		this.accountingDocumentAccountingDocumentExpenseCollection.setFilter(
			'accountingDocument',
			accountingDocument.get('@id')
		);

		this.accountingDocumentDeadlineCollection.setFilter(
			'accountingDocument',
			accountingDocument.get('@id')
		);
		this.accountingDocumentDeadlineCollection.addSort('deadline', true);

		this.accountingDocumentExpenseCollection.setFilter(
			'accountingDocumentExpenses.accountingDocument',
			accountingDocument.get('@id')
		);

		this.accountingDocumentHistoryCollection.setFilter(
			'accountingDocument',
			accountingDocument.get('@id')
		);
		this.accountingDocumentHistoryCollection.addSort('createdAt', false);

		this.accountingDocumentVatCollection.setFilter('accountingDocument', accountingDocument.get('@id'));
		this.accountingDocumentVatCollection.addSort('vat.rate', false);

		this.accountingDocumentVoucherCollection.setFilter(
			'expensePaymentVoucherAccountingDocumentExpenses.accountingDocumentExpense.accountingDocument',
			accountingDocument.get('@id')
		);

		const promises = [
			this.accountingDocumentAccountingDocumentExpenseCollection.list(),
			this.accountingDocumentDeadlineCollection.list(),
			this.accountingDocumentExpenseCollection.list(),
			this.accountingDocumentHistoryCollection.list(),
			this.accountingDocumentVatCollection.list(),
			this.accountingDocumentVoucherCollection.list(),
		];

		return Promise
			.all(promises)
			.then(() => {
				this.currentAccountingDocument = accountingDocument;
				return true;
			});
	}

	public loadExpenseCollection(accountingDocumentId) {
		this.expenseCollection.setFilter(
			'accountingDocumentExpenses.accountingDocument',
			accountingDocumentId
		);

		return this.expenseCollection.list();
	}

	public loadVoucherCollection(accountingDocumentId) {
		this.voucherCollection.setFilter(
			'expensePaymentVoucherAccountingDocumentExpenses.accountingDocumentExpense.accountingDocument',
			accountingDocumentId
		);

		return this.voucherCollection.list();
	}

	public loadOrganigramEntitiesFromCompany(companyId) {
		this.projectCollection.setFilter('projectSubCategory.projectCategory.projectPole.company', companyId);
		this.subCategoryCollection.setFilter('projectCategory.projectPole.company', companyId);
		this.categoryCollection.setFilter('projectPole.company', companyId);
		this.poleCollection.setFilter('company', companyId);
		this.expenseGroupCollection.setFilter('company', companyId);

		return Promise.all([
			this.projectCollection.list(),
			this.subCategoryCollection.list(),
			this.categoryCollection.list(),
			this.poleCollection.list(),
			this.expenseGroupCollection.list(),
		]);
	}
}

export default new AccountingDocumentsSplitViewStore();
