import FondationsApiModel from '../global/FondationsApiModel';
import { computed }       from 'mobx';

export default class UserModel extends FondationsApiModel {
	static get path(): string {
		return '/users/{?id}';
	}

	@computed
	get userMediaProfilePictureObjectUrl() {
		return this.get('userMediaProfilePicture.objectUrl');
	}

	@computed
	get firstName(): string {
		return this.get('firstName');
	}

	@computed get lastName() {
		return this.get('lastName');
	}

	@computed
	get fullName() {
		return this.firstName + ' ' + this.lastName;
	}

	@computed
	get email() {
		return this.get('email');
	}

	@computed
	get enabled() {
		return this.get('enabled');
	}

	@computed
	get fullNameFirstLetters() {
		let wbcPictureEmptyText = '';
		wbcPictureEmptyText += this.get('firstName') ? this.get('firstName')[0] : '';
		wbcPictureEmptyText += this.get('lastName') ? this.get('lastName')[0] : '';
		return wbcPictureEmptyText;
	}
}
