import { lazy } from 'react';

const EntitySplitViewScreen = lazy(
	() => import(
		/* webpackChunkName: "entitySplitViewScreen" */
		'./screens/EntitySplitViewScreen')
);

const EntityDashboardScreen = lazy(
	() => import(
		/* webpackChunkName: "entityDashboardScreen" */
		'./screens/EntityDashboardScreen')
);

export const routes = [
	{
		component: EntitySplitViewScreen,
		exact: true,
		hideBreadcrumb: true,
		path: '/settings/entities',
	},
	{
		component: EntityDashboardScreen,
		exact: true,
		path: '/settings/entities/:id',
	},
];
