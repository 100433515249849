import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class ExpensePaymentVoucherAccountingDocumentExpenseModel extends LsdApiModel {
	static get path(): string {
		return '/expense_payment_voucher_accounting_document_expenses/{?id}';
	}

	public _filters: ModelFiltersExtended<{
		'accountingDocumentExpense': id,
		expensePaymentVoucher: id,
		'expensePaymentVoucher.expense': id,
		'id': id,
	}> = {};

	public _sorts: ModelSortsExtended<{
	}> = {};

	@computed
	get accountingDocumentExpenseId() {
		return this.get('accountingDocumentExpense.id');
	}

	@computed
	get accountingDocumentExpenseIri() {
		return this.get('accountingDocumentExpense.@id');
	}

	@computed
	get expensePaymentVoucherId() {
		return this.get('expensePaymentVoucher.id');
	}

	@computed
	get amount() {
		return this.get('amount');
	}

	@computed
	get amountInCompanyCurrency() {
		return this.get('amountInCompanyCurrency');
	}

	@computed
	get currencyIri() {
		return this.get('currency.@id');
	}
}
