import { observer }                          from 'mobx-react';
import React                                 from 'react';
import jsTools                               from '../../tools/jsTools';
import SelectAntd                            from 'antd/lib/select';
import Trans                                 from '../../components/Trans';
import { SelectProps }                       from 'antd/lib/select';
import { ApiCollection as NewApiCollection } from 'models/collections/ApiCollection';
import { ApiCollection }                     from 'modelx-jts-common';

const { removeAccents } = jsTools;

export type SelectCollectionProps = SelectProps & {
	autoload?: boolean;
	collection?: ApiCollection | NewApiCollection<Model>;
	property?: string;
};

@observer
class Select extends React.Component<SelectProps> {
	public static defaultProps = {
		allowClear: true,
		dropdownMatchSelectWidth: false,
		filterOption: (input, option: any) => {
			let optionValue = option.props.children;

			if (Array.isArray(optionValue)) {
				optionValue = optionValue.join('');
			}

			optionValue = removeAccents(optionValue.toLowerCase());

			const inputValue = removeAccents(input.toLowerCase());

			return optionValue.indexOf(inputValue) >= 0;
		},
		notFoundContent: <Trans id="Aucun résultat" />,
		optionFilterProp: 'children',
		size: 'large',
		showSearch: true,
	};

	public static Option = SelectAntd.Option;

	public render() {
		return (
			<SelectAntd
				{...this.props}
			>
				{this.props.children}
			</SelectAntd>
		);
	}
}

export default Select;
