import { ApiConnector } from 'modelx-jts-common';
import {
	ConnectorResult,
	Model,
}                       from '@mathquis/modelx';

export class FileDownloadConnector extends ApiConnector {

	public fetch(model: Model, options?: object): Promise<ConnectorResult> {
		return this
			.request(`${model.path}`, {
				method: 'get',
				...options,
				responseType: 'blob',
			})
			.then(response => {
				// @ts-ignore
				return new ConnectorResult({ blob: response.data, }, response);
			});
	}
}
