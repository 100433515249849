import {
	Model,
	ConnectorResult,
}                                      from '@mathquis/modelx';
import { PlatformPrivateApiConnector } from './PlatformPrivateApiConnector';

export class PlatformFileUploadConnector extends PlatformPrivateApiConnector {

	public save(model: Model, options: any = {}) {
		return this
			.request(model.path, {
				method: 'post',
				data: options.patchAttributes || model.untransformedAttributes,
				...options
			})
			.then((response: any) => {
				return new ConnectorResult(response.data, response);
			});
	}

	protected request(path: string, options: object = {}) {
		return this
			.client(path, options)
			.catch((err) => {
				// if ( err instanceof UnauthorizedError ) {
				// 	console.warn('OOPS!', err.message);
				// 	return this.login('user', 'password').then((response) => {
				// 		return this.request(path, options);
				// 	});
				// }
				throw err;
			});
	}
}
