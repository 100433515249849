import LsdApiModel  from './global/LsdApiModel';
import { computed } from 'mobx';

export default class ProjectCategoryModel extends LsdApiModel {
	static get path(): string {
		return '/project_categories/{?id}';
	}

	@computed
	get activatedExpenseGroups() {
		return this.get('activatedExpenseGroups', []);
	}

	@computed
	get projectPoleId() {
		return this.get('projectPole.id');
	}

	@computed
	get title() {
		return this.get('title');
	}
}
