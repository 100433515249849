import FondationsApiModel from '../global/FondationsApiModel';
import { computed }       from 'mobx';
import moment             from 'moment';

export default class EntityModel extends FondationsApiModel {
	static get path(): string {
		return '/entities/{?id}';
	}

	@computed
	get createdAt() {
		return this.get('createdAt');
	}

	@computed
	get createdAtMoment() {
		return moment(this.createdAt);
	}

	@computed
	get state() {
		return this.get('state');
	}

	@computed
	get entityType() {
		return this.get('entityType');
	}

	@computed
	get commercialName() {
		return this.get('commercialName', '');
	}

	@computed
	get corporateName() {
		return this.get('corporateName', '');
	}

	@computed
	get provider() {
		return this.get('provider');
	}

	@computed
	get entityCategoryName() {
		return this.get('entityCategory.name');
	}

	@computed
	get activityDomainTitle() {
		return this.get('activityDomain.title');
	}

	@computed
	get identificationNumber() {
		return this.get('identificationNumber');
	}

	@computed
	get intraCommunityVATNumber() {
		return this.get('intraCommunityVATNumber');
	}

	@computed
	get subjectToVAT() {
		return this.get('subjectToVAT');
	}

	@computed
	get website() {
		return this.get('website');
	}

	@computed
	get websiteWithHttps() {
		if (!this.website) {
			return '';
		}

		return this.website.indexOf('https://') > -1
			? this.website
			: 'https://' + this.website;
	}

	@computed
	get corporateAndCommercialName() {
		let corporateAndCommercialName = '';

		if (!!this.corporateName) {
			corporateAndCommercialName = this.corporateName + ' ';
		}

		if (!!this.commercialName) {
			corporateAndCommercialName += `<${this.commercialName}>`;
		}

		return corporateAndCommercialName;
	}
}
