import { Model }               from '@mathquis/modelx';
import { WebStorageConnector } from '@mathquis/modelx-webstorage-connector';
import Connector               from '@mathquis/modelx/lib/types/connector';
import session                 from '../currentSession';

const localStorageConnector = new WebStorageConnector({ storage: localStorage }) as any;

export default class SessionUser extends Model {
	static get connector(): Connector {
		return localStorageConnector;
	}

	static get path(): string {
		return '/user/current';
	}

	static get defaults(): object {
		return {
			id: 'current',
			user_data: {
				user_informations: {
					avatar: '',
					firstName: '',
					lastName: ''
				}
			},
			token: '',
			refreshToken: '',
		};
	}

	public isManager(): boolean {
		return !!session.getRoleUrnArr().find(role => !!role.match(/LSD/g) && !!role.match(/MANAGER/g));
	}

	public isAdmin() {
		return !!session.getRoleUrnArr().find(role => !!role.match(/LSD/g) && !!role.match(/ADMIN/g));
	}

	public isSuperAdmin() {
		return !!session.getRoleUrnArr().find(role => !!role.match(/LSD/g) && !!role.match(/SUPER_ADMIN/g));
	}

	protected initialize() {
		this
			.fetch()
			.catch(() => {
				// just catch message
			});
	}
}
