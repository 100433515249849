export const getProperty = (obj, path, defaultValue = null) =>
	String.prototype.split.call(path, /[,[\].]+?/)
		.filter(Boolean)
		.reduce((array, key) => (Object.hasOwnProperty.call(array, key) ? array[key] : defaultValue), obj);

export const getUniqueDefinedValues = (...args) => {
	const set = new Set();

	args.forEach(value => {
		if (Array.isArray(value)) {
			value.forEach(v => set.add(v));
		} else {
			set.add(value);
		}
	});

	return Array.from(set).filter(v => !!v || v === 0);
};

export const inArray = (array, search, equal) => {
	// tslint:disable-next-line:prefer-for-of
	for (let i = 0; i < array.length; i++) {
		if (equal(array[i], search)) {
			return true;
		}
	}
	return false;
};

export const pushIfNotExist = (array, toPush, equal) => {
	if (!inArray(array, toPush, equal)) {
		array.push(toPush);
	}
};

export const removeAccents = str => {
	return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export default {
	getProperty,
	getUniqueDefinedValues,
	inArray,
	pushIfNotExist,
	removeAccents,
};
