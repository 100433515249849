import FondationsApiModel from '../global/FondationsApiModel';
import { computed }       from 'mobx';

type MethodType = 'POST' | 'PUT' | 'DELETE' | 'GET';

export default class UserMeRightsModel extends FondationsApiModel {
	static get path(): string {
		return '/users/me/rights';
	}

	@computed
	public get isReady(): boolean {
		return !this.isLoading && this.isLoaded;
	}

	@computed
	public get userAuthorizedApiRegExp(): { GET: RegExp[], DELETE: RegExp[], POST: RegExp[], PUT: RegExp[] } {
		const resByMethod = {GET: [], DELETE: [], POST: [], PUT: []};

		if (!this.isReady) {
			return resByMethod;
		}

		Object.keys(resByMethod).forEach(method => {
			const attributes = this.attributes[method];

			resByMethod[method] = Object.keys(attributes)
				.filter(k => attributes[k])
				.map(k => new RegExp(k))
			;
		});

		return resByMethod;
	}

	public isAuthorizeToMethodPath(method: MethodType, path: string): boolean {
		// Si les droits sont en cours de récupération sur l'api => return false
		if (!this.isReady) {
			return false;
		}

		const p = path.replace(/\/$/, '');

		return !!this.userAuthorizedApiRegExp[method].find(regExp => {
			return (
				!!p.match(regExp)
				// Les droits ont été préfixés par "fondations" côté API. Hack pour que ça puisse continuer à fonctionner
				|| !!`/fondations${p}`.match(regExp)
			);
		});
	}

	public isAuthorizeToMethodsPath(methods: MethodType[], path: string): boolean {
		return !methods.find(method => !this.isAuthorizeToMethodPath(method, path));
	}

	public isAuthorizedToGet(path: string): boolean {
		return this.isAuthorizeToMethodPath('GET', path);
	}

	public isAuthorizedToPut(path: string): boolean {
		return this.isAuthorizeToMethodPath('PUT', path);
	}

	public isAuthorizedToPost(path: string): boolean {
		return this.isAuthorizeToMethodPath('POST', path);
	}

	public isAuthorizedToDelete(path: string): boolean {
		return this.isAuthorizeToMethodPath('DELETE', path);
	}
}
