import BudgetModel                  from './BudgetModel';
import { computed }                 from 'mobx';
import { getLabel }                 from '../constants/ExpenseStatus';
import { PaymentMethodsReferences } from '../constants/PaymentMethods';

export default class ExpenseBudgetModel extends BudgetModel {
	static get path(): string {
		return '/expenses/_budget';
	}

	@computed
	public get applicantIri() {
		return this.get('applicant.@id');
	}

	@computed
	public get amountInCompanyCurrency() {
		return this.get('amountInCompanyCurrency');
	}

	@computed
	public get budgetToValidateAmount() {
		return this.get('budgetToValidate.amount');
	}

	@computed
	public get budgetToValidateNbItems() {
		return this.get('budgetToValidate.nbItems');
	}

	@computed
	public get budgetValidatedAmount() {
		return this.get('budgetValidated.amount');
	}

	@computed
	public get budgetValidatedNbItems() {
		return this.get('budgetValidated.nbItems');
	}

	@computed
	public get budgetPaidAmount() {
		return this.get('budgetPaid.amount');
	}

	@computed
	public get budgetPaidNbItems() {
		return this.get('budgetPaid.nbItems');
	}

	@computed
	public get budgetToPayAmount() {
		return this.get('budgetToPay.amount');
	}

	@computed
	public get budgetToPayNbItems() {
		return this.get('budgetToPay.nbItems');
	}

	@computed
	public get currentResponsibles() {
		return this.get('currentResponsibles', []);
	}

	@computed
	public get currentResponsiblesIds() {
		return this.currentResponsibles.map(data => data.id);
	}

	@computed
	public get closed() {
		return this.get('closed', false);
	}

	@computed
	public get comment() {
		return this.get('comment');
	}

	@computed
	public get createdById() {
		return this.get('createdBy.id');
	}

	@computed
	public get createdByIri() {
		return this.get('createdBy.@id');
	}

	@computed
	public get currencyIri() {
		return this.get('currency.@id');
	}

	@computed
	public get description() {
		return this.get('description');
	}

	@computed
	public get expenseReportForIri() {
		return this.get('expenseReportFor.@id');
	}

	@computed
	get paymentMethod() {
		return this.get('paymentMethod');
	}

	@computed
	get paymentMethodLabel() {
		if (!this.paymentMethod) {
			return '';
		}

		return PaymentMethodsReferences[this.paymentMethod].label;
	}

	@computed
	get previousYearAmount() {
		return this.get('previousYearAmount', 0);
	}

	@computed
	get previousYearAmountInCompanyCurrency() {
		return this.get('previousYearAmountInCompanyCurrency', 0);
	}

	@computed
	get previousYearExpenseId() {
		return this.get('previousYearExpense.id');
	}

	@computed
	get previousYearExpenseIri() {
		return this.get('previousYearExpense.@id');
	}

	@computed
	get projectId() {
		return this.get('project.id');
	}

	@computed
	get projectIri() {
		return this.get('project.@id');
	}

	@computed
	get priority() {
		return this.get('priority', false);
	}

	@computed
	get status() {
		return this.get('status');
	}

	@computed
	get statusLabel() {
		return getLabel(this.status);
	}

	@computed
	get title() {
		return this.get('title');
	}
}
