import FondationsApiModel from '../global/FondationsApiModel';
import { computed }       from 'mobx';
import resolvable         from '../../resolvables/resolvable';
import UserModel          from './UserModel';

export default class UserUserGroupModel extends FondationsApiModel {
	static get path(): string {
		return '/user_user_groups/{?id}';
	}

	@computed
	get userId() {
		return this.get('user.id');
	}

	@resolvable({ urnPropertyName: 'user.@urn' })
	public userModel = new UserModel();
}
