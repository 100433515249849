import LsdApiModel                      from './global/LsdApiModel';
import { action, computed, observable } from 'mobx';
import { getModelFromUrn }              from '../tools/modelUrnTools';
import { getIdFromUrn }                 from '../tools/lsdTools';

export default class SupplierModel extends LsdApiModel {
	static get path(): string {
		return '/suppliers/{?id}';
	}

	@observable
	private _supplier: Model | void;

	@computed
	public get entityUrn() {
		return this.get('entityUrn', '');
	}

	@computed
	public get entityId() {
		return getIdFromUrn(this.entityUrn);
	}

	@computed
	public get supplierIsLoaded(): boolean {
		return this._supplier && this._supplier.isLoaded;
	}

	@computed
	public get supplierIsLoading(): boolean {
		return this._supplier && this._supplier.isLoading;
	}

	@computed
	public get commercialOrCorporateName() {
		return !!this.commercialName ? this.commercialName : this.corporateName;
	}

	@computed
	public get corporateAndCommercialName() {
		let corporateAndCommercialName = '';

		if (!!this.corporateName) {
			corporateAndCommercialName = this.corporateName + ' ';
		}

		if (!!this.commercialName) {
			corporateAndCommercialName += `<${this.commercialName}>`;
		}

		return corporateAndCommercialName;
	}

	@computed
	public get commercialName() {
		if (this.get('entity')) {
			return this.get('entity.commercialName');
		}

		if (this._supplier && this._supplier.id) {
			return this._supplier.get('commercialName', '');
		}

		return '';
	}

	public get corporateName() {
		if (this.get('entity')) {
			return this.get('entity.corporateName');
		}

		if (this._supplier && this._supplier.id) {
			return this._supplier.get('corporateName', '');
		}

		return '';
	}

	public async supplierFetchAsync(): Promise<Model | void> {
		if (this._supplier && this._supplier.id && !this._supplier.isLoading && !this._supplier.isLoaded) {
			await this._supplier.fetch();
		}
	}

	protected transform(attributes) {
		const result = super.transform(attributes);

		if (!this._supplier || !this._supplier.id) {
			const supplierLsdModel = getModelFromUrn(attributes.entityUrn);

			if (supplierLsdModel) {
				this.setSupplier(supplierLsdModel);
			}
		}

		return result;
	}

	@action
	public setSupplier(supplierLsdModel) {
		this._supplier = supplierLsdModel;
	}
}
