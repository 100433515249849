import './ExpenseGroupsNavigation.scss';

import React             from 'react';
import Menu              from 'antd/lib/menu';
import {
	inject,
	observer,
}                        from 'mobx-react';
import { matchPath }     from 'react-router';
import { NavLink }       from 'react-router-dom';
import {
	IconSvg,
	JtsPicture,
}                        from 'react-jts-common';
import currentMenu       from '../../currentMenu';
import dashboardSvg      from '../../../icons/dashboard.svg';
import depensesSvg       from '../../../icons/depenses.svg';
import euroSvg           from '../../../icons/euro.svg';
import MenuExpenseNotice from './MenuExpenseNotice';
import routes            from '../../routes';
import Trans             from '../Trans';

export interface ISubNavigationProps {
	appStore?: any;
	location: any;
	organigramStore?: any;
}

@inject('appStore')
@inject('organigramStore')
@observer
class ExpenseGroupsNavigation extends React.Component<ISubNavigationProps> {
	public render() {
		const {
			appStore,
			organigramStore,
		} = this.props;

		let selectedKeys: string[] = [];

		this._getCurrentSelectedKeys(selectedKeys, routes);

		const {pathname} = this.props.location;
		const reg = /\/companies\/(\d+)\/expense_groups\/(\d+)/;
		const res = pathname.match(reg);

		let companyId = null;
		let expenseGroupId = null;

		if (!!res) {
			companyId = res[1];
			expenseGroupId = res[2];
			if (!!res && !!companyId && !!expenseGroupId) {
				selectedKeys = [`/companies/${companyId}/expense_groups/${expenseGroupId}`];
			}
		}

		// overwritte for expense dashboard
		const regexExpenseDashboard = /\/expenses\/:expenseId/;
		let matchExpenseDashboard = false;
		if (!!selectedKeys[0]) {
			matchExpenseDashboard = !!selectedKeys[0].match(regexExpenseDashboard);
		}

		return <div className="company-sub-navigation">
			<Menu
				defaultSelectedKeys={selectedKeys}
				selectedKeys={selectedKeys}
				className="sub-navigation-menu"
				theme="dark"
			>

				<Menu.Item
					key="section_global"
					className={`section-menu-globalView${currentMenu.get('isOpen') ? ' special-case' : ''}`}
				>
					{!currentMenu.get('isOpen') ? <Trans id="Vue globale"/> : <Trans id="VG"/>}
				</Menu.Item>

				<Menu.Item key={`${this._prefixPath('my-tasks')}/`}>
					<NavLink to={this._prefixPath('my-tasks')}>
						<IconSvg className="nav-icon" style={{ backgroundColor: '#3777BC' }} svg={depensesSvg} />
						<span><Trans id="Mes dépenses à traiter"/></span>
					</NavLink>
				</Menu.Item>

				<Menu.Item key={`${this._prefixPath('my-vouchers')}/`}>
					<NavLink to={this._prefixPath('my-vouchers')}>
						<IconSvg className="nav-icon" style={{backgroundColor: '#e67e22'}} svg={depensesSvg}/>
						<span><Trans id="Mes paiements à traiter"/></span>
					</NavLink>
				</Menu.Item>

				<Menu.Item key={`${this._prefixPath('accounting-documents')}/`}>
					<NavLink to={this._prefixPath('accounting-documents')}>
						<IconSvg className="nav-icon" style={{backgroundColor: '#1abc9c'}} svg={euroSvg}/>
						<span><Trans id="Pièces comptables"/></span>
					</NavLink>
				</Menu.Item>

				<Menu.Item key={`${this._prefixPath('state')}/`}>
					<NavLink to={this._prefixPath('state')}>
						<IconSvg className="nav-icon" style={{backgroundColor: '#A16388'}} svg={dashboardSvg}/>
						<span><Trans id="Tableau de bord société"/></span>
					</NavLink>
				</Menu.Item>

				<Menu.Item
					key="section_expense_group"
					className={`section-menu-generalState${currentMenu.get('isOpen') ? ' special-case' : ''}`}
				>
					{/*{!currentMenu.get('isOpen') ? <Trans id="Groupes de dépenses"/> : <Icon type="down-square" />}*/}
					{!currentMenu.get('isOpen') ? <Trans id="Groupes de dépenses"/> : <Trans id="GD"/>}
				</Menu.Item>

				{appStore.currentCompanyExpenseGroupCollection.map(expenseGroup => {
					let menuItemKey = this._prefixPath(`expense_groups/${expenseGroup.id}`);

					if (
						!!matchExpenseDashboard
						&& expenseGroup.id === appStore.currentExpenseGroupId
					) {
						menuItemKey = selectedKeys[0];
					}

					return <Menu.Item
						onClick={() => {
							appStore.setCurrentExpenseGroupId(expenseGroup.id);
							organigramStore.expenseGroupId = expenseGroup.id;
						}}
						key={menuItemKey}
					>
						<NavLink to={this._prefixPath(`expense_groups/${expenseGroup.id}/expenses`)}>
							<div className="nav-icon-container">
								<div className="nav-icon-subcontainer">
									<JtsPicture
										uri={expenseGroup.get('logo.objectUrl')}
										height={34} width={34}
										previewDisabled
									/>
									<MenuExpenseNotice
										companyId={expenseGroup.get('company.id')}
										expenseGroupId={expenseGroup.id}
									/>
								</div>
							</div>

							<span>{expenseGroup.get('name')}</span>
						</NavLink>
					</Menu.Item>;
				})}
			</Menu>
		</div>;
	}

	private _prefixPath(path: string) {
		const {appStore} = this.props;

		return `/companies/${appStore.currentCompanyId}/${path}`;
	}

	private _getCurrentSelectedKeys(matchedRoutes, navRoutes) {
		const {currentCompanyId} = this.props.appStore;

		for (const route of navRoutes) {
			const routePath = route.path.replace(':companyId', currentCompanyId);
			const matchConfig = {
				path: routePath,
				exact: routePath === '/'
			};

			if (matchPath(this.props.location.pathname, matchConfig) && routePath !== '/*') {
				matchedRoutes.push(routePath);
			}

			if (route.routes) {
				this._getCurrentSelectedKeys(matchedRoutes, route.routes);
			}
		}
	}
}

export default ExpenseGroupsNavigation;
