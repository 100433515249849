import { computed }                from 'mobx';
import currentModelXMercureService from '../../currentModelXMercureService';
import ConfigProxy                     from '../../tools/ConfigProxy';
import { PlatformPrivateApiConnector } from '../connectors/PlatformPrivateApiConnector';
import { AbstractMercureModel }        from '../abstracts/AbstractMercureModel';

export const lsdConnector = new PlatformPrivateApiConnector({
	baseURL: ConfigProxy.getServiceConfig('lsd', 'api_endpoint'),
});

export default class LsdApiModel extends AbstractMercureModel {
	static get connector() {
		return lsdConnector;
	}

	protected service = currentModelXMercureService;

	public patch(attributes: object = {}, options: object = {}) {
		return this.save({...options, patchAttributes: attributes});
	}

	@computed
	public get key() {
		return this.constructor.name + '-' + this.id;
	}

	@computed
	public get pageUrl() {
		return this.path;
	}

	@computed
	public get id(): number {
		return this.get('id');
	}

	@computed
	public get iri(): string {
		return this.get('@id');
	}

	@computed
	public get urn(): string {
		return this.get('@urn');
	}

	@computed
	public get isEmpty(): boolean {
		return !this.get('@type');
	}
}
