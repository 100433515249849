import React           from 'react';
import Select          from '../../genericComponents/Selects/Select';
import Genders         from '../../constants/Genders';
import { SelectProps } from 'antd/lib/select';

const SelectGender = (props: SelectProps) => (
	<Select
		{...props}
	>
		{Genders.map((gender, idx) => (
			<Select.Option
				key={idx}
				value={gender.value}
			>
				{gender.label}
			</Select.Option>
		))}
	</Select>
);

export default SelectGender;
