import '../../styles/ant-theme.less';
import './App.scss';

import React                        from 'react';
import ConfigProvider               from 'antd/lib/config-provider';
import { I18nManager }              from 'components/i18n';
import { Provider as MobxProvider } from 'mobx-react';
import mobxStore                    from 'store/index';
import { Suspense }                 from 'react';
import Spin                         from 'antd/lib/spin';
import frFR                         from 'antd/lib/locale-provider/fr_FR';
import { Switch }                   from 'react-router';
import { BrowserRouter }            from 'react-router-dom';
import { Route }                    from 'react-router-dom';
import jaJP                         from 'antd/lib/locale-provider/ja_JP';
import enGB                         from 'antd/lib/locale-provider/en_GB';
import moment                       from 'moment/moment';
import { loadCatalogs }             from 'components/i18n';
import i18n                         from 'components/i18n';
import currentSession               from '../../currentSession';

// import { l as catalogFr }                    from '../../../locale/fr_FR/messages.js';
// import { l as catalogJa }                    from '../../../locale/ja_JP/messages.js';

import 'moment/dist/locale/fr';
moment.locale('fr');

const renderRoutes = routes => {
	return (
		<Switch>
			{routes.map((route, index) => {
				if (typeof route.component === 'object') {
					const LazyComponent = route.component;
					return (
						<Route
							key={`${route.path}_${index}`}
							path={route.path}
							exact={!!route.exact}
							render={props => (
								<LazyComponent {...props} />
							)}
						/>

					);
				}

				if (!!route.routes) {
					const RoutesComponent = route.component;
					return (
						<Route
							key={`${route.path}_${index}`}
							path={route.path}
							exact={!!route.exact}
							render={props => (
								<RoutesComponent
									{...props}
									route={route}
								>
									{renderRoutes(route.routes)}
								</RoutesComponent>
							)}
						/>
					);
				}

				return (
					<Route
						key={`${route.path}_${index}`}
						path={route.path}
						exact={!!route.exact}
						component={route.component}
					/>
				)
			})}
		</Switch>
	);
}

interface TestState {
	catalogs: any;
	language: string;
	loaded: boolean;
	locale: any;
}

class App extends React.Component<{
	history: any;
	routes: any[];
}> {
	public state: TestState = {
		catalogs: {},
		language: 'fr_FR',
		loaded: false,
		locale: frFR,
	};

	public async componentDidMount() {
		await Promise.all([
			currentSession.internalInitialize(),

			this._loadLanguage(this.state.language),
		]);


		// load group collection at start
		if (
			!mobxStore.appStore.currentCompanyGroupCollection.isLoaded
			&& !mobxStore.appStore.currentCompanyGroupCollection.isLoading
		) {
			mobxStore.appStore.currentCompanyGroupCollection
				.list()
				.then(() => mobxStore.appStore.orderGroupById());
		}

		mobxStore.appStore.history = this.props.history;

		this.setState({ loaded: true });
	}

	public render() {
		const supportsHistory = typeof window !== 'undefined' && 'pushState' in window.history;

		if (!this.state.loaded) {
			return null;
		}

		return (
			<ConfigProvider locale={this.state.locale}>
				<I18nManager
					language={this.state.language}
					catalogs={this.state.catalogs}
					// languageData={languageData}
				>
					<MobxProvider {...mobxStore}>
						<Suspense
							fallback={
								<div className="lsd-suspense">
									<Spin spinning />
								</div>
							}
						>
							<BrowserRouter
								forceRefresh={!supportsHistory}
							>
								{renderRoutes(this.props.routes)}
							</BrowserRouter>
						</Suspense>
					</MobxProvider>
				</I18nManager>
			</ConfigProvider>
		);
	}

	private _loadLanguage = async (language) => {
		const languageShort = language.split('_')[0];

		let locale, catalogs;

		switch (languageShort) {
			case 'en':
				catalogs = {};
				locale = enGB;
				moment.locale('en');
				break;
			case 'ja':
				// catalogs = catalogJa;
				catalogs = {};
				locale = jaJP;
				moment.locale('ja');
				break;
			case 'fr':
			default:
				// catalogs = catalogFr;
				catalogs = {};
				locale = frFR;
				moment.locale('fr');
		}

		this.setState(
			(prevState: TestState) => ({
				catalogs: {
					...prevState.catalogs,
					[language]: catalogs,
				},
				locale,
			}),
			() => {
				loadCatalogs(this.state.catalogs);

				i18n.activate(language);
			}
		);
	};
}

export default App;