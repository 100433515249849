import LsdApiModel  from './global/LsdApiModel';
import { computed }                              from 'mobx';
import { ExpensePaymentVoucherStatus, getLabel } from '../constants/ExpensePaymentVoucherStatus';

export default class ExpensePaymentVoucherModel extends LsdApiModel {
	static get path(): string {
		return '/expense_payment_vouchers/{?id}';
	}

	@computed
	public get accountingDocumentExpense() {
		return this.get('accountingDocumentExpense');
	}

	@computed
	public get amount() {
		return this.get('amount');
	}

	@computed
	public get amountInCompanyCurrency() {
		return this.get('amountInCompanyCurrency');
	}

	@computed
	public get amountPaid() {
		return this.get('amountPaid');
	}

	@computed
	public get amountPaidInCompanyCurrency() {
		return this.get('amountPaidInCompanyCurrency');
	}

	@computed
	public get bankDetailId() {
		return this.get('bankDetail.id');
	}

	@computed
	public get bankDetailIri() {
		return this.get('bankDetail.@id');
	}

	@computed
	public get currencyIri() {
		return this.get('currency.@id');
	}

	@computed
	public get currentResponsible() {
		return this.get('currentResponsible');
	}

	@computed
	public get currentResponsibleId() {
		return this.currentResponsible?.id;
	}

	@computed
	public get expenseId() {
		return this.get('expense.id');
	}

	@computed
	public get position() {
		return this.get('position');
	}

	@computed
	public get comment() {
		return this.get('comment');
	}

	@computed
	public get desiredPaymentMethod() {
		return this.get('desiredPaymentMethod', '');
	}

	@computed
	public get paymentComment() {
		return this.get('paymentComment');
	}

	@computed
	public get paymentMethod() {
		return this.get('paymentMethod');
	}

	@computed
	public get status(): ExpensePaymentVoucherStatus {
		return this.get('status');
	}

	@computed
	public get statusLabel() {
		return getLabel(this.status);
	}
}
