import { ConnectorResult }             from '@mathquis/modelx';
import { PlatformPrivateApiConnector } from '../connectors/PlatformPrivateApiConnector';

// const cache = new LRU({max: 500, maxAge: 1000 * 60 * 60});

export default (connectorConstructor: new (options) => PlatformPrivateApiConnector) => {
	return class extends connectorConstructor {
		public async fetch(model: Model, options: object = {}) {
			// const cachedAttributes = cache.get(model.path);
			//
			// if (cachedAttributes) {
			// 	console.info('Cache found for => ' + model.path, cachedAttributes);
			//
			// 	return new ConnectorResult(cachedAttributes);
			// }

			const connectorResult = await super.fetch(model, options);

			// cache.set(model.path, connectorResult.attributes);

			return connectorResult;
		}

		public async list(collection, options) {
			const connectorResults = await super.list(collection, options);
			const tempCacheModel = new collection.model();

			connectorResults.items.forEach(attributes => {
				// cache.set(tempCacheModel.set(attributes).path, attributes);
			});

			return connectorResults;
		}
	};
};
